import React from 'react';
import { Link } from 'gatsby';
import { StaticQuery, graphql } from "gatsby"

const Footer = () => {

    const ProductMenuQuery = () => {
        return (
            <StaticQuery
              query={graphql`
              {
                allMarkdownRemark(filter: {frontmatter: {type: {eq: "category"}}}) {
                  edges {
                    node {
                      frontmatter {
                        url
                        title
                      }
                    }
                  }
                }
              }
              `}
              render={data => (
                    
                <ProductMenu data={data}/>
              )}
            />
          )
    }  

    //dont know if this is the best way to do this but running out of time
    const ProductMenu = ({data}) => {
        return data.allMarkdownRemark.edges.map((e,i) => <li key={"submenu-"+ i}><Link to={`/${e.node.frontmatter.url}`}><span>{e.node.frontmatter.title}</span></Link></li>)
    }

    return (

        <footer>
            <div className="inner">
                <nav className="gala">
                    <h3>GALA</h3>
                    <ul>
                        <li><Link to="/about-us" className="gala-link"><span>About Us</span></Link></li>
                        <li><Link to="/support" className="gala-link"><span>Support</span></Link></li>
                        <li><Link to="/contact-us" className="gala-link"><span>Contact Us</span></Link></li>
                    </ul>
                  
                </nav>
                <nav className="product-list">
                    <h3>Products</h3>
                    <ul>
                        <ProductMenuQuery />
                    </ul>
                </nav>

                <div className="contact-details">
                    <h3>Contact</h3>
                   
                    <div className="contact-phone">
                        <a href="tel:+6492620995"><span className="title">Phone:</span> <span>(+64) 09 262 0995</span></a>
                    </div>
                    <div className="contact-mobile">
                        <a href="tel:+6421915690"><span className="title">Mobile:</span> <span>(+64) 021 915 690</span></a>
                    </div>
                    <div className="aus-site">
                        <a href="https://www.galasolutions.com.au/"><span className="title">AU Site:</span> <span>www.galasolutions.com.au</span></a>
                    </div>


                </div>
                
              
            
            </div>

            <p className="copyright">&copy; GALA Solutions Ltd {new Date().getFullYear()}</p>
        </footer>
       
    )

}

export default Footer